import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Container, Row, Col } from 'react-bootstrap';

import Layout from '../components/layout/layout';
// import Seo from '../components/seo/seo';
import FreedomBanner from '../components/freedomBanner/freedomBanner';
import SectionHero from '../components/wordPress/sections/sectionHero/sectionHero';
import Seo from '../components/seo/seo';

const WhoWeArePage = ({ data }) => {
  const page = data.wpPage.pageWhoWeAre;

  return (
    <Layout>
      <Seo
        canonical={`https://www.essentialbehavioral.com/who-we-are/`}
      />

      <>
        <SectionHero
          buttonDefaults={data.wp.globalContent.globalButtons.buttonSettings}
          sectionContent={page.whoWeAreHeader}
        />

        <section className={`py-5`}>
          <Container>
            <Row className={`justify-content-center text-center`}>
              <Col xs={11} md={8}>
                <h2>Our Mission</h2>
                <p>Founded on the belief that substance use and mental health disorders require personalized treatment, Clean &amp; Sober Detox provides individualized care for every client. Through counseling, experienced medical staff, and a lifelong support system, our mission is to give every client the tools they need to sustain recovery after treatment.</p>
                <p>&nbsp;</p>
                <h2>A Team of Award-Winning Healthcare Providers</h2>
                <pre>Hands-on experience at every level fuels our passion for mental health.</pre>
                <p>&nbsp;</p>
                <h3>Our Team</h3>
                <p>Our team of clinicians, mental health professionals and medical experts bring more than 40 years of experience in the field. The hands-on approach of our leadership staff adds a level of compassion and first-hand knowledge that sets us apart as a truly client-first team.</p>
              </Col>
            </Row>
          </Container>
        </section>

        <hr />

        <section className={`py-5 pb-3`}>
          <Container>
            <Row className={`justify-content-center`}>
              <Col xs={12} md={6} className={`mb-4`}>
                <div className={`text-center mb-3`}>
                  <Img
                    fluid={page.teamMembers[0].headshot.localFile.childImageSharp.fluid}
                    alt={page.teamMembers[0].headshot.altText}
                    className={`rounded-circle d-block mx-6 mb-3`}
                  />
                  <h4>Andrew Mendonsa<br/><small>Psy. D., MBA</small></h4>
                  <h5>C.O.O.</h5>
                </div>
                <div>Dr. Andrew Mendonsa currently serves as the Chief Operational Officer for Clean &amp; Sober Detox, a subsidiary of Sprout Health. He earned his doctorate and master’s degrees in Clinical Psychology from California School of Professional Psychology, and a MBA from Texas A&amp;M University – a top tier and highly ranked business school. Andrew is licensed in California as a Clinical Psychologist and is also a recognized Qualified Medical Evaluator (QME). He has earned various professional and community accolades including the prestigious 40 Under 40 Award and a Congressional Medal for community service.<br/>
                  <br/>Dr. Mendonsa’s vision and business skills have earned him a well-documented history of proven success in leadership within the mental health and substance abuse treatment industries. He is a recognized media expert appearing on various local and national media networks discussing topics around treatment and recovery. Before leading Clean &amp; Sober Detox, Dr. Mendonsa maintained a thriving private practice, worked for a large healthcare system, and oversaw contract forensic treatment programs for local and state law enforcement agencies.
                </div>
              </Col>

              <Col xs={12} md={6} className={`mb-4`}>
                <div className={`text-center mb-3`}>
                  <Img
                    fluid={page.teamMembers[1].headshot.localFile.childImageSharp.fluid}
                    alt={page.teamMembers[1].headshot.altText}
                    className={`rounded-circle d-block mx-6 mb-3`}
                  />
                  <h4>Jody Ressio<br/><small>MA, LAADC, IS</small></h4>
                  <h5>Executive Director</h5>
                </div>
                <div>Jody serves at the Executive Director at Clean &amp; Sober Detox. She brings extensive administrative experience working in private, nonprofit, and medically assisted substance abuse treatment facilities. She has a MA in Psychology, is a Licensed Advanced Alcohol and Drug Counselor, and is a certified interventionist. Jody has over 18 years of her own sobriety and has chosen this field to give hope and success to people who are suffering with addiction. Jody brings both passion and expertise to program oversight at Clean &amp; Sober Detox and is dedicated to ensuring that our clients receive the highest quality services on their road to recovery.</div>
              </Col>
            </Row>
          </Container>
        </section>

        <FreedomBanner />
      </>
    </Layout>
  );
};

export const query = graphql`
  query {
    wpPage(databaseId: { eq: 266 }) {
      title
      seo {
        metaTitle
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          localFile {
            publicURL
          }
        }
      }
      pageWhoWeAre {
        whoWeAreHeader {
          fieldGroupName
          content
          htmlClass
          background {
            backgroundType
            backgroundImage {
              localFile {
                ...imageFluidLarge
              }
            }
            backgroundVideo {
              localFile {
                publicURL
              }
            }
            backgroundVideoCover {
              localFile {
                publicURL
              }
            }
          }
          buttonLayout {
            showButton
            overrideButtonSettings
            buttonSettings {
              buttonText
              linkType
              internalLink {
                ... on WpPage {
                  uri
                }
                ... on WpPost {
                  uri
                }
              }
              externalLink
              htmlClass
            }
          }
        }
        whoWeAreContent
        teamMembers {
          name
          qualifications
          title
          bio
          headshot {
            localFile {
              ...imageFluidMedium
            }
            altText
          }
        }
      }
    }
    wp {
      globalContent {
        globalButtons {
          buttonSettings {
            buttonText
            linkType
            internalLink {
              ... on WpPost {
                id
                uri
              }
              ... on WpPage {
                id
                uri
              }
            }
            externalLink
            htmlClass
          }
        }
      }
    }
  }
`;

export default WhoWeArePage;
